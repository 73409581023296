<template>
    <div>
        <Chart :cdata="cdata" />
    </div>
</template>

<script>
import Chart from './chart.vue'
import * as api from '@/api/index'
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            cdata: {
                xAxis: [],
                seriesData: []
            },
            month:[],   // 最近12个月数组
        };
    },
    components: {
        Chart,
    },
    watch: {
        data: {
            async handler(newv, oldv) {
                // console.log(newData,this.month, '洗涤趋势7777777handler---------------')
                let category = await api.getCategoryPage({pageNo:1,pageSize:10})
                let typeArr = category.data.list.map(item=>{
                    return {
                        categoryName:item.name,
                        num:0
                    }
                })

                let newData = JSON.parse(JSON.stringify(newv))
                this.month = this.getMonth()
                let indexArr = []   // [10,11]
                this.month.map((item,index)=>{
                    let arr = item.split('-')
                    newv = newData.map((item2,index2)=>{
                        if(item2.month == arr[1]){
                            indexArr.push(index)
                            item2.index = index
                        }
                        return item2
                    })
                })
                this.month.map((item,index)=>{
                   if(indexArr.indexOf(index) == -1){
                    newData[index] = {
                        month:item,
                        num:0,
                        total:0,
                        type:typeArr
                    }
                   }else{
                    let arr = newv.find(item2 => item2.index == index )
                    let typeData = arr.type.map(item=>{
                        return {
                            categoryName:item.categoryName,
                            num:item.num,
                        }
                   })
                    let resultArr = typeArr.concat(typeData)
                    arr.type = this.removeSame(resultArr,'categoryName')
                    arr.month = item
                    newData[index] = arr
                   }
                })
                if (newData) {
                    const arr = []
                    this.cdata.seriesData = newData.map(v => {
                        if (v.type.length > 0) {
                            v.total = v.type.reduce((pre, ctx) => pre + ctx.num, 0)
                        } else {
                            v.total = 0
                        }
                        this.cdata.xAxis.push(v.month)
                        return {
                            value: v.num,
                            month: v.month,
                            type: v.type,
                            total: v.total
                        }
                    })
                }
            },
            deep: true,
            immediate: true,
        }
    },
    
    mounted() {
        
    },
    methods: {
        getMonth() {
            var dataArr = [];
            var data = new Date();
            var year = data.getFullYear();
            data.setMonth(data.getMonth() + 1, 1); //获取到当前月份,设置月份
            for (var i = 0; i < 12; i++) {
                data.setMonth(data.getMonth() - 1); //每次循环一次 月份值减1
                var m = data.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                dataArr.push(data.getFullYear() + "-" + m);
            }
            return dataArr.reverse();
        },
        removeSame(arr,u_key) {   // 数组根据某个key去重
            const obj = {}
            const result = []
            arr.forEach(item => {
                const typeof_key = typeof item[u_key] + item[u_key]
                obj[typeof_key] = item
            })
            for (const key in obj) {
                result.push(obj[key])
            }
            return result
        }
    }
};
</script>

<style lang="scss" scoped></style>