<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <!-- <span>
          <icon name="align-left" class="text-icon"></icon>
        </span> -->
        <span class="fs-xl text_title mx-2">报废织物排名</span>
      </div>
      <div class="d-flex padding-xs flex-column body-box">
        <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
        <!-- <centerRight2Chart1 /> -->
        <div class=" d-flex d-title">
          <div class=" d-flex jc-end fz17 bfsl">报废数量</div>
          <div class=" d-flex jc-end fz17">平均洗涤寿命</div>
        </div>
        <div class="d-flex">
          <dv-scroll-ranking-board class="top_scrap" :config="config" style="width:70%;height:180px" />
          <ul class="ul_item" style="width:30%">
            <li v-for="(item, idx) in [9,7,5,6,8,6,7]" :key="idx" class="d-flex ">
              <span class="numWashing">{{ item }}</span>
               <!-- <span class="numWashing">666</span> -->
            </li>
          </ul>
        </div>
        <div class="d-flex jc-center text-info-1 fs-lg" style="margin-top: 5px;font-size:1.278rem;height:30px;">
          报废总数：{{ total }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'
import * as api from '@/api/index'
export default {
  data() {
    return {
      total: 0,
      config: {
        waitTime: 10000,
        data: [
          {
            name: '病房被套',
            value: 263
          },
          {
            name: '病房床笠',
            value: 186
          },
          {
            name: '护工服',
            value: 169
          },
          {
            name: '手术服',
            value: 141
          },
          {
            name: '手术帽',
            value: 98
          },
          {
            name: '隔离衣',
            value: 79
          },
          {
            name: '毛巾',
            value: 56
          }
        ]
      },
      colors: ['red', '#e55132', '#dd752e', '#e7a649', '#96d70f'],
      params: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        customerId: ''
      },
      result: []
    }
  },
  // components: { centerRight2Chart1 }
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId
    this.total = this.config.data.reduce((pre, ctx) => pre + ctx.value, 0)
    // this.getData()
  },
  methods: {
    async getData() {
      const res = await api.getBigSkuDispose(this.params)
      console.log(res.Result, '织物报废-----------')
      if (res.ResultCode == 0) {
        this.result = res.Result
        this.total = res.Result.reduce((pre, ctx) => pre + ctx.num, 0)

        this.config.data = res.Result.sort(function (a, b) {
          return b.num - a.num; // 从大到小排序
        }).map(v => {
          return {
            name: v.spuName,
            value: v.num,
          }
        });
        this.config = { ...this.config }
        // console.log(this.result, 'this.result---------')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 410px;
  $box-width: 500px;
  padding: 16px;
  width: calc(100% - 32px);
  // height: $box-height;
  width: 100%;
  border-radius: 5px;
  padding-bottom: 0px;

  ::v-deep .dv-scroll-ranking-board .ranking-info .ranking-value {
    // display: none;
  }

  ::v-deep .top_scrap .ranking-column {
    border-color: #484443;

    .inside-column {
      background-color: #e77d35;
    }
  }

  .ul_item {
    height: 180px;
    overflow-y: auto;

    li {
      padding:0px 10px 17px 10px;
      align-items: center;
      text-align: center;
      .numWashing{
        font-size: 22px;
        display: inline-block;
        width: 100%;
        color:#00cdff;
      }

      .span_t {
        width: 100px;
      }

      .item_li {
        width: 280px;
        margin-right: 5px;
      }

      .default_num {
        max-width: 260px;
        height: 15px;
        background-color: #e77d35;
        text-align: center;
      }
    }
  }

  .bg-color-black {
    // padding: 5px;
    height: 280px;
    // width: $box-width;
    border-radius: 10px;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 0px;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .jc-end{
    margin-bottom: 10px
  }
  .d-title{
    justify-content: space-between;
    margin-top: 10px;
  }
  .bfsl{
    width: 70%;
    text-align: right;
  }
  .fz17{
    font-size: 17px;
  }
}
</style>
