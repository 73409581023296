<template>
    <div class="summary_list">
        <ul>
            <li v-for="(item, index) in list" :key="index">
                <div class="text_count padding-bottom-lg">{{ item.count }}</div>
                <span class="text_name fs-md">
                    <i class="el-icon-menu"></i>
                    <span>{{ item.name }}</span>
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
import * as api from '@/api/index'
export default {
    data() {
        return {
            list: [
                {
                    name: '今日报废数量',
                    count: 87
                },
                {
                    name: '今日登记数量',
                    count: 243
                },
                {
                    name: '今日出库数量',
                    count: 1000
                },
                {
                    name: '今日芯片更换',
                    count: 13
                },
            ],
            params: {
                // pageNum: 1,
                // pageSize: 10000,
                // startTime: '',
                // endTime: '',
                customerId: ''
            },
        }
    },
    mounted() {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        this.params.customerId = this.$route.query.customerId

        this.getData()
    },
    methods: {
        async getData() {
            const res = await api.getSkuCountToday(this.params)
            if (res.ResultCode == 0) {
                // console.log(res.Result.numOut, '今日登记，报废，出库，芯片更换-----------')

                this.list.map(v => {
                    if (v.name == '今日报废数量') {
                        v.count = 152
                    } else if (v.name == '今日登记数量') {
                        v.count = 2648
                    } else if (v.name == '今日出库数量') {
                        v.count = 3875
                    } else if (v.name == '今日芯片更换') {
                        v.count = 1861
                    }
                    return v
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.summary_list {
    padding-top: 35px;
    padding-bottom: 10px;

    ul {
        display: grid;
        grid-template-columns: 20% 20% 20% 20%;
        justify-content: center;
        align-content: center;

        li {
            text-align: center;

            .text_count {
                color: #00cdff;
                font-size: 35px;
            }

            .text_name {
                i {
                    color: #00cdff;
                }
            }
        }
    }
}
</style>