<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cdata: {
        xData: ["data1", "data2", "data3", "data4", "data5", "data6"],
        seriesData: [
          { value: 10, name: "data1" },
          { value: 5, name: "data2" },
          { value: 15, name: "data3" },
          { value: 25, name: "data4" },
          { value: 20, name: "data5" },
          { value: 35, name: "data6" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  watch: {
    data: {
      handler(newv, oldv) {
        // console.log(newv, '6666666handler---------------')
        if (newv) {
          this.cdata.xData = []
          const arr = []
          this.cdata.seriesData = newv.map(v => {
            this.cdata.xData.push(v.categoryName)
            return {
              value: v.num,
              name: v.categoryName
            }
          })
        }
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped></style>