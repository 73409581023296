<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import * as api from '@/api/index'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cdata: {
        seriesData: [],
        data: []
      }
    }
  },
  watch: {
    data: {
      async handler(newv, oldv) {
        // console.log(newv, '织物分析---------------')
        if (newv) {
          // xAias数组
          const xAias = ['0-20', '21-40', '41-60', '61-80', '81-100', '101-120', '121-140'];
          const arr = await api.getCategoryPage({pageNo:1,pageSize:10})
          const list = arr.data.list

          // 调用函数并打印结果
          // console.log(this.addNumbersByRange(newv, xAias), '调用函数并打印结果');
          // this.cdata.data = this.addNumbersByRange(newv, xAias)
  
          let categorys = Array.from(list,({name})=>name)
          let arrs = categorys.map((item,index)=>{
            return new Array(categorys.length).fill(0)
          })

          newv.forEach(function(item,index){
              for(var j in item.numCategory){
                      let idx = categorys.indexOf(j)
                      arrs[idx][index] = item.numCategory[j]
              }
          })


          this.cdata.data = [
          [235, 185, 123, 88, 43, 15,10],
          [6226, 4689, 3654, 1598, 695, 268,150],
          [7505, 5986, 5695, 2475, 1459, 596,268],
          [10959, 8561, 6325, 2594, 1896, 1251,689],
          [2123, 1856, 1458, 1232, 555, 369,258],
          [1256, 865, 695, 475, 145, 369,196],
          [1256, 865, 695, 475, 145, 369,147],
          ]
          this.cdata.seriesData = list
          console.error('ggggggggggggggg',arrs)
          // newv.map(v => {
          //   this.cdata.seriesData.push(v.categoryName)
          // })
        }
      },
      deep: true,
      immediate: true,
    }
  },
  components: {
    Chart,
  },
  mounted() {

  },
  methods: {
    addNumbersByRange(arr, xAias) {
      // console.log(arr, 'arr.length++++++')
      // 首先，我们需要将xAias中的范围转换为一个可以索引的范围数组
      const rangeIndexes = xAias.map(range => {
        const [start, end] = range.split('-').map(Number);
        // 返回一个包含范围开始索引和宽度的新对象
        return { start, end, width: end - start + 1 };
      });
      // console.log(rangeIndexes, 'rangeIndexes------------')
      // 初始化一个新的二维数组
      const newArr = new Array(arr.length).fill(0).map(() => new Array(arr.length).fill(0));
      // console.log(newArr, '---------------------newArr')
      // 遍历arr数组，根据num值填充到对应的二维数组位置
      arr.forEach((item, index) => {
        // 遍历rangeIndexes，找到匹配的范围
        rangeIndexes.forEach((range, idx) => {
          // console.log(item.num, 'num++++++++', range.start, range.end)
          // 检查num是否在当前范围内
          if (item.num >= range.start && item.num <= range.end) {

            // 如果在范围内，则将num填充到对应的二维数组位置
            newArr[index][idx] = item.num;
          }
        });
      });

      // 返回新的二维数组
      return newArr;
    }
  }
}



</script>