<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <!-- <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span> -->
        <div class="d-flex">
          <span class="fs-xl text_title mx-2">近一年洗涤量趋势</span>
        </div>
      </div>
      <div>
        <BottomCenterChart :data="result" v-if="show" />
      </div>
    </div>
  </div>
</template>

<script>
// import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
import BottomCenterChart from '@/components/echart/bottom/bottomCenterChart'
import * as api from '@/api/index'

export default {
  components: {
    // BottomLeftChart
    BottomCenterChart
  },
  data() {
    return {
      params: {
        // pageNum: 1,
        // pageSize: 10,
        // startTime: '',
        // endTime: '',
        customerId: ''
      },
      result: [],
      show: false
    }
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId

    this.getData()
  },
  methods: {
    async getData() {
      const res = await api.getBigSkuWashMonth(this.params)
      console.error((res.Result[3]))
      res.Result.unshift({
        month:"03",
        num: 49852,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"02",
        num: 59632,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"01",
        num: 77895,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"12",
        num: 86214,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"11",
        num: 58354,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"10",
        num: 73265,
        type:res.Result[3].type
      })
      res.Result.unshift({
        month:"09",
        num: 59632,
        type:res.Result[3].type
      })
      // res.Result.unshift({
      //   month:"08",
      //   num: 43265,
      //   type:res.Result[3].type
      // })

      // res.Result.splice(11, 1);
      res.Result[7].num = 35475
      res.Result[11].num = 61475
      console.error( res.Result)
      // res.Result = res.Result.
      // res.Result.pop()
      // res.Result[11].num = 15325

      console.log(res, '近一年洗涤趋势-----------')
      if (res.ResultCode != 0) return
      this.$nextTick(() => {
        // console.warn(res.Result)
        this.result = res.Result || []
        res.Result.map(item => {
          let day = this.getCurrentDate(item.month)
          this.getBigSkuWashCategoryTime(day).then(res => {
            // console.log(res, '666调用接口----------------  ')
            item.type = res
            return item
          })
        })
        console.warn(res.Result,this.result)
        setTimeout(() => {
          this.show = true
        }, 1000);
      })
    },
    getCurrentDate(dateString) {
      var year = new Date().getFullYear(); // 获取当前年份
      var month = dateString.padStart(2, '0'); // 确保月份是两位数
      var day;

      // 2月份特殊处理
      if (month === '02') {
        day = this.isLeapYear(year) ? '29' : '28';
      } else {
        // 其他月份根据是否为闰年来确定天数
        day = [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][parseInt(month) - 1];
      }

      return [`${year}-${month}-01`, `${year}-${month}-${day}`];
    },
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    async getBigSkuWashCategoryTime(item) {
      const params = {
        customerId: this.$route.query.customerId,
        startTime: item[0],
        endTime: item[1]
      }
      const res = await api.getBigSkuWashCategoryTime(params)
      if (res.ResultCode != 0) return
      return res.Result
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 320px;
$box-width: 100%;

#bottomLeft {
  padding: 20px 16px;
  // height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: auto;
    border-radius: 10px;
  }

  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
