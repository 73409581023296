<template>
  <div>
    <Echart :options="options" id="centreLeft1Chart" height="255px" width="30vw"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          color: [
            "#37a2da",
            "#6871d0",
            "#4d9f88",
            "#ffdb5c",
            "#006de6",
            "#00d2f5",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 10,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff",
              fontSize: 18
            }
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: [25, 55],
              // roseType: "area",
              center: ["50%", "40%"],
              data: newData.seriesData,

              label: {
                normal: {
                  textStyle: {
                    fontSize: 15,
                    color: '#fff'
                  },
                  formatter: function (params) {
                    // console.log(params, '--------')
                    return params.name + ':' + (parseInt(params.percent)) + '% ' + params.value + '件'
                  },
                }

              },
              labelLine: {
                normal: {
                  length: 10, //第一条线
                  length2: 15, //第二条线
                  lineStyle: {
                    width: 1, // 线条的宽度
                    // color: "#000", //线的颜色设置， 如没有设置颜色则线条的颜色跟随饼状图的颜色
                  }
                }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>