<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div style="margin-top:0;padding-top:0;">
          <div class="d-flex jc-center project-title">
            医用织物智能一体化管理中心
          </div>
          <div class="react-right mr-4 react-l-s">
            <span class="react-after"></span>
            <span class="text fs-lg">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
          </div>
          <div class="custom-seamless">
            <div class="text fs-lg">
              全球领先的RFID产品和解决方案提供商
            </div>
          </div>
          <div class="user-info fs-xl text-info-1">
            <i class="el-icon-user-solid"></i>
            {{ username }}
          </div>
        </div>
        <!-- <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">医用织物数字化管理平台</span>
              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div> -->

        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text fs-xl">国 家 高 新 技 术 企 业</span>
            </div>
            <div class="react-left bg-color-blue ml-3">
              <span class="text"></span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b"></span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text fs-xl">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div class="border-box-contaier" >
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
           <div class="border-box-contaier">
              <dv-border-box-12>
                <centerRight2 />
              </dv-border-box-12>
            </div>
             <div class="border-box-contaier">
              <dv-border-box-13>
                <centerLeftBottom />
              </dv-border-box-13>
            </div>
          </div>
          <div class="center">
            <div>
              <summaryList />
            </div>
            <div>
              <dv-border-box-13>
                <centerRight1 />
              </dv-border-box-13>
            </div>
            <div class="bottomLeft">
              <dv-border-box-13>
                <bottomLeft />
              </dv-border-box-13>
            </div>

          </div>
          <!-- 第四行数据 -->
          <div class="bottom-box">
            <div class="border-box-contaier">
              <dv-border-box-12>
                <rightTop />
              </dv-border-box-12>
            </div>
            <div class="border-box-contaier">
              <dv-border-box-12>
                <rightCenter />
              </dv-border-box-12>
            </div>
            <div class="border-box-contaier">
              <dv-border-box-12>
                <rightBottom />
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import { setCache, getCache } from '../utils';
import centerLeft1 from './centerLeft1'
// import centerLeft2 from './centerLeft2'
import rightCenter from './rightCenter'
import centerRight1 from './centerRight1'
import centerLeftBottom from './centerLeftBottom'
import summaryList from './summaryList'
import centerRight2 from './centerRight2'
// import center from './center'
import bottomLeft from './bottomLeft'
import rightTop from './rightTop'
import rightBottom from './rightBottom'

import * as Api from '../api/index.js'
export default {
  // mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      username: ''
    }
  },
  components: {
    centerLeft1,
    // centerLeft2,
    rightCenter,
    rightBottom,
    centerRight1,
    centerRight2,
    summaryList,
    centerLeftBottom,
    // center,
    bottomLeft,
    rightTop
  },

  mounted() {
    this.timeFn()
    this.cancelLoading()
    console.log('userInfo9999999999999',localStorage.getItem('userInfo'))
    // this.username = JSON.parse(localStorage.getItem('accountPsw')).v.username
    this.username = JSON.parse(localStorage.getItem('userInfo')).userName
    // console.error(JSON.parse(getCache('accountPsw')))
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
